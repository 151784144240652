<template>
  <div class="menu">
    <div class="topNav">店铺名称</div>
    <div class="storeContent">
      <!-- 分类 -->
      <div class="left">
        <div v-for="(item,index) in categoryArr"
          :key="index"
          class="categoryItem"
          :class="item.id == categoryId && 'categoryActive'"
          @click="chooseCategory(item.id)">
          <div class="block"
            :class="item.id == categoryId && 'activeBlock'"></div>
          {{item.name || '空'}}
        </div>
      </div>
      <div class="right">
        <div class="productItem"
          v-for="(item,index) in productArr"
          :key="index">
          <div class="leftImg">
            <img :src="item.image ? item.image.thumbnailStaticUrl : require('@/assets/productDefault.png')">
          </div>
          <div class="rightMsg">
            <div class="titleBox">{{item.title1 || '未命名'}}</div>
            <div class="tagBox">
              <van-tag v-for="(tag,tagIndex) in item.tags"
                :key="tagIndex"
                type="primary"
                color="#ebebeb"
                text-color="#666">{{tag}}</van-tag>
            </div>
            <div class="saleNum">
              销量：{{Number(item.sumSale)}}
            </div>
            <div class="priceBox">
              <div class="num">$ {{Number(item.unitPrice)}}</div>
              <div>
                <!-- 单规格 -->
                <div v-if="!moreSeettings.length"
                  class="noSku">
                  <van-button type="info"
                    size="mini"
                    v-if="item.carNumber"
                    @click="reduce(index)"> - </van-button>
                  <div class="number"
                    v-if="item.carNumber">{{item.carNumber}}</div>
                  <van-button type="info"
                    size="mini"
                    @click="add(index)">+</van-button>
                </div>
                <!-- 多规格 -->
                <div v-else>
                  <van-badge :content="item.carNumber"
                    v-if="item.carNumber>0">
                    <van-button type="info"
                      size="mini"
                      @click="openProp(index,item)">选规格</van-button>
                  </van-badge>
                  <van-button type="info"
                    size="mini"
                    @click="openProp(index,item)"
                    v-else>选规格</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <infinite-loading @infinite="getProductList"
          :identifier="infiniteId"
          direction="bottom">
          <div slot="spinner">读取中...</div>
          <div slot="no-more"
            class="no-more">已加载全部</div>
          <div slot="no-results"
            class="no-results">
            <img :src="require('@/assets/kong.png')">
          </div>
        </infinite-loading>
      </div>
    </div>
    <!-- 规格 -->
    <van-popup v-model="popupShow"
      :round="true">
      <div class="popupContent">
        <div class="productTitle">{{clickProduct.title1 || '未命名'}}</div>
        <!-- 规格 -->
        <div class="label">规格</div>
        <div class="tagBox">
          <van-tag :plain="selectGuid.value != guest.value"
            type="primary"
            size="large"
            v-show="clickProduct[guest.key]>0 && guest.value"
            @click="chooseGuid(guest)"
            v-for="(guest,key) in guests"
            :key="key">{{guest.value}}</van-tag>
        </div>
        <!-- 规格 -->
        <div class="label">口味偏好</div>
        <div class="tagBox">
          <van-tag :plain="selectedMemo.indexOf(memoItem.memo) < 0"
            type="primary"
            size="large"
            v-for="(memoItem,memoIndex) in moreSeettings"
            :key="memoIndex"
            @click="chooseMemo(memoItem.memo)">{{memoItem.memo}}</van-tag>
        </div>
        <!-- 已选 -->
        <div>
          已选：{{selectGuid.value}}、{{selectedMemo}}
        </div>
        <!-- 价格 -->
        <div class="priceBox">
          <div class="total">
            <span>总计</span>
            <span class="red">￥</span>
            <span class="red big">{{Number(clickProduct[selectGuid.key]) || '--'}}</span>
          </div>
          <van-button type="info"
            round
            size="small"
            @click="addCar">
            <i class="iconfont icon-gouwuche-tianchong"></i>
            加入购物车
          </van-button>
        </div>
        <div class="closeBox">
          <i class="iconfont icon-guanbi2"
            @click="closePrpo"></i>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'menu',
  data() {
    return {
      // 分类
      categoryId: 0,
      categoryArr: [],
      // 产品
      productQuery: {
        offset: 0,
        limit: 20,
        categoryId: 0
      },
      productArr: [],
      productTotal: 0,
      productLoading: false,
      infiniteId: +new Date(),
      // 弹窗
      popupShow: false,
      productIndex: -1,
      clickProduct: {},
      // 口味偏好
      moreSeettings: [],
      selectedMemo: [],
      // 份数
      guests: {},
      selectGuid: {},
      // 购物车
      carList: JSON.parse(localStorage.getItem('car')) || []
    }
  },
  mounted() {
    if (this.$route.query.url && this.$route.query.id) {
      localStorage.setItem('url', this.$route.query.url)
      localStorage.setItem('id', this.$route.query.id)
    }
    this.getMoreSetting()
    this.getCategories()
    this.getGuest()
  },
  methods: {
    // 获取分类列表
    getCategories() {
      this.$http
        .get(
          `https://${localStorage.getItem(
            'url'
          )}/be/api/restaurant/guest/product/categories`,
          {
            params: {
              limit: 999
            }
          }
        )
        .then(res => {
          this.categoryArr = res.data.data.list
          this.categoryArr.unshift({
            name: '全部',
            id: 0
          })
        })
        .catch(err => {})
    },
    // 选择分类
    chooseCategory(categoryId) {
      this.categoryId = categoryId
      this.productQuery.categoryId = categoryId
      this.productArr = []
      this.productQuery.offset = 0
      this.getProductListOnePage()
    },
    // 获取产品
    getProductList($state) {
      if (this.productLoading) {
        return
      } else {
        this.productLoading = true
        this.$http
          .get(
            `https://${localStorage.getItem(
              'url'
            )}/be/api/restaurant/guest/products`,
            {
              params: { ...this.productQuery }
            }
          )
          .then(res => {
            if (res.data.data.list.length) {
              this.productQuery.offset += 20
              res.data.data.list.map(item => {
                item.carNumber = 0
              })
              this.productArr.push(...res.data.data.list)
              this.productLoading = false
              if ($state) {
                $state.loaded()
              }
            } else {
              if ($state) {
                $state.complete()
              }
              this.isLoading = false
            }
          })
          .catch(err => {})
      }
    },
    // 获取产品第一页
    getProductListOnePage() {
      this.$http
        .get(
          `https://${localStorage.getItem(
            'url'
          )}/be/api/restaurant/guest/products`,
          {
            params: { ...this.productQuery }
          }
        )
        .then(res => {
          if (res.data.data.list.length) {
            this.productQuery.offset += 20
            this.productArr = res.data.data.list
            this.productLoading = false
            if ($state) {
              $state.loaded()
            }
          }
        })
        .catch(err => {})
    },
    // 获取口味
    getMoreSetting() {
      this.$http
        .get(`https://${localStorage.getItem('url')}/be/api/handy/memos`, {
          params: {
            type: 'SaleOrderMemo',
            page: 1,
            offset: 0,
            limit: 999
          }
        })
        .then(res => {
          this.moreSeettings = res.data.data.handyMemos
        })
    },
    chooseMemo(memo) {
      let index = this.selectedMemo.indexOf(memo)
      if (index > -1) {
        this.selectedMemo.splice(index, 1)
      } else {
        this.selectedMemo.push(memo)
      }
    },
    // 获取份数
    getGuest() {
      this.$http
        .get(
          `https://${localStorage.getItem(
            'url'
          )}/be/api/restaurant/guest/settings`
        )
        .then(res => {
          this.guests = res.data.data.list
          this.guests.UnitUnit.key = 'unitPrice'
          this.guests.BagUnit.key = 'bagPrice'
          this.guests.BigBagUnit.key = 'bigBagPrice'
          this.guests.BoxUnit.key = 'boxPrice'
        })
    },
    // 选择份
    chooseGuid(guid) {
      this.selectGuid = guid
    },
    // 添加
    add(index) {
      this.productArr[index].carNumber++
    },
    // 减少
    reduce(index) {
      this.productArr[index].carNumber--
    },
    // 选规格
    openProp(index, item) {
      this.productIndex = index
      this.clickProduct = item
      this.popupShow = true
    },
    // 关闭弹窗
    closePrpo() {
      this.popupShow = false
    },
    // 添加购物车
    addCar() {
      this.carList.push({
        ...this.clickProduct,
        memo: `${this.selectGuid.value}、${this.selectedMemo}`,
        carPrice: this.clickProduct[this.selectGuid.key],
        buyGuid: this.selectGuid.key
      })
      localStorage.setItem('car', JSON.stringify(this.carList))
      this.clickProduct = {}
      this.popupShow = false
      this.selectedMemo = []
      this.selectGuid = {}
      this.$toast('添加成功')
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  height: calc(100% - 6rem);
  padding-top: 4rem;
  box-sizing: border-box;
}
.topNav {
  background: white;
  height: 4rem;
  width: 100%;
  line-height: 4rem;
  text-align: center;
  font-size: 1.4rem;
  box-shadow: 0px 0px 15px -7px #999;
  position: fixed;
  top: 0;
  left: 0;
}
.storeContent {
  position: relative;
  height: 100%;
  padding-left: 10rem;
  text-align: left;
  .left {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 10rem;
    overflow: auto;
  }
  .right {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
.categoryItem {
  padding: 0 5px;
  padding-left: 20px;
  height: 6rem;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  position: relative;
  .block {
    position: absolute;
    left: 0px;
    width: 0.6rem;
    height: 100%;
  }
  .activeBlock {
    background: #1989fa;
  }
}
.categoryActive {
  background: white;
}
.productItem {
  display: flex;
  width: 100%;
  height: 14rem;
  box-sizing: border-box;
  .leftImg {
    padding: 1rem;
    img {
      height: 12rem;
      width: 12rem;
      object-fit: contain;
      background: #f5f5f5;
    }
  }
  .rightMsg {
    flex: 1;
    padding: 1rem 0;
    box-sizing: border-box;
    height: 100%;
    border-bottom: 1px solid #f5f5f5;
    .titleBox {
      font-weight: 600;
      height: 3rem;
      line-height: 1.5rem;
      font-size: 1.6rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .tagBox {
      height: 2rem;
      display: flex;
      align-content: center;
      margin-top: 1rem;
    }
    .saleNum {
      margin-top: 1rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 1.2rem;
    }
    .priceBox {
      margin-top: 1rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 1rem;
      .num {
        color: #ee0a24;
        font-size: 1.8rem;
        font-weight: 600;
      }
      .noSku {
        display: flex;
        align-items: center;
        .number {
          margin: 0 0.6rem;
        }
      }
      .van-button {
        height: 2rem;
      }
    }
  }
}
.van-popup {
  overflow-y: visible;
  border-radius: 8px;
}
// 弹框
.popupContent {
  min-height: 20rem;
  text-align: left;
  position: relative;
  width: 32rem;
  padding: 1rem;
  border-radius: 20px;
  .productTitle {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .label {
    font-size: 1.4rem;
    color: #999;
  }
  .tagBox {
    margin: 1rem 0 1.8rem 0;
    .van-tag {
      margin-right: 0.8rem;
    }
  }
  .priceBox {
    margin-top: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total {
      font-size: 1.6rem;
      font-weight: 600;
      .red {
        color: #ee0a24;
      }
      .big {
        font-size: 2rem;
      }
    }
  }
  .closeBox {
    position: absolute;
    bottom: -4rem;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    .icon-guanbi2 {
      font-size: 2.8rem;
    }
  }
}
</style>

